/*global $:false*/
/*global escape: true */
/*jslint browser: true*/

$(window).resize(function () {
	WindowSize();
	MoveElements();
	moveCTAPanel();
	
	// Equal Heights on wait time boxes
	$("#CTAPanels h2, #CTAPanels .cmspanel, #CareersHome #BelowMainLeftRight h2, #CareersLeftCol #BelowMainLeftRight h2, .grid-2col li, .grid-4col li").css('height', 'auto');
	$("#CTAPanels h2, #CTAPanels .cmspanel, #CareersHome #BelowMainLeftRight h2, #CareersLeftCol #BelowMainLeftRight h2, .grid-2col li, .grid-4col li").css('height', 'auto');
	//equalHeight($("ul.WaitTimes li"));
	equalHeight($("#CTAPanels h2"));
	equalHeight($("#CTAPanels .cmspanel"));
	equalHeight($("#CareersHome #BelowMainLeftRight h2"));
	equalHeight($("#CareersLeftCol #BelowMainLeftRight h2"));
	equalHeight($(".grid-2col li"));
	equalHeight($(".grid-4col li"));
	equalHeight($(".grid-4col li"));
});
$(document).ready(function () {
	showScheduleButton();
	WindowSize();
	MoveElements();
	moveCTAPanel();

	// Equal Heights on wait time boxes
	//$("ul.WaitTimes li").css('height', 'auto');
	//equalHeight($("ul.WaitTimes li"));
	//	equalHeight($("#CTAPanels h2"));
	//	equalHeight($("#CTAPanels .cmspanel"));
	equalHeight($("#CareersHome #BelowMainLeftRight h2"));
	equalHeight($("#CareersLeftCol #BelowMainLeftRight h2"));
	equalHeight($(".grid-2col li"));
	equalHeight($(".grid-4col li"));

	/* Error App template 
    ----------------------------------------------------------------- */
	// pulling in actual navigation from home page into app/error pages
	if ($('body.ErrorApp').length > 0) {
		$('#Logo .cmsPanelContent a').each(function (i, el) {
			$.ajax({
				url: $(el).attr('href'),
				success: function (contents) {
					$('#MainNav').append($(contents).find('#MainNav').html());
				}
			});
		});
	}

	$('#left-menu').sidr({
		name: 'sidr-left',
		side: 'left' // By default
	});
	$('#right-menu').sidr({
		name: 'sidr-right',
		side: 'right'
	});
	$('.MobileSmall #Banner .focuspoint, .MobileSmall #Banner2 .focuspoint').focusPoint({
		reCalcOnWindowResize: true,
		throttleDuration: 100,
	});
	$('.MobileSmall .FlexBanner .focuspoint').focusPoint({
		reCalcOnWindowResize: true,
		throttleDuration: 100,
	});

	// $('#Content .Inline-Block.CampusMap a').removeClass('fancybox');

	//Since we are adding the ability to 'Move-Inline-CTA-Here' for mobile and desktop, we have to add a starting point so we can move it back on desktop
	if ($('#Content .CTA, #Content .CTAWide').length > 0) {
		$('<div class="CTAOrig"></div').insertAfter('#Content .CTA, #Content .CTAWide');
	}

});
$(window).on("load",function() {
	$("#CTAPanels h2, #CTAPanels .cmspanel").css('height', 'auto');
	equalHeight($("#CTAPanels h2"));
	equalHeight($("#CTAPanels .cmspanel"));
});

function WindowSize() {
	var w = Math.max($(window).width(), window.innerWidth);
	var type = 'Desktop';

	// css at 800 for Mobile and 1000 for Tablet
	if (w <= 1000) {
		type = 'Tablet';
	}

	// css at 660 for Mobile and 1000 for Tablet
	if (w <= 800) {
		type = 'Mobile';
	}

	// css at 660 for Mobile and 1000 for Tablet
	if (w <= 600) {
		type = 'MobileSmall';
	}

	// change the css file of the tag with id="stl" and rel="stylesheet"
	$('body').removeClass('Desktop').removeClass('Tablet').removeClass('Mobile').removeClass('MobileSmall').addClass(type);
}

//make columns equal heights
function equalHeight(group) {
	var tallest = 0;
	group.each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > tallest) {
			tallest = thisHeight;
		}
	 });
	group.css('height', tallest);
}

function MoveElements() {

	// Equal Heights for Wait Time boxes

	// this is so equal heights runs after ajax
	// $(document).ajaxStop(function(group){
	//   moveElements();
	// });

	if ($("body.Desktop").length) {
		if ($("body").hasClass("sidr-open")) {

			//alert("help");
			$.sidr('close', 'sidr-right');
			$.sidr('close', 'sidr-left');
		}
	}

	$('.MobileSmall #Banner .focuspoint, .MobileSmall #Banner2 .focuspoint').focusPoint({
		reCalcOnWindowResize: true,
		throttleDuration: 100,
	});

	$('.MobileSmall .FlexBanner .focuspoint').focusPoint({
		reCalcOnWindowResize: true,
		throttleDuration: 100,
	});

	$('body.MobileSmall #MainNavWrap2').appendTo('#sidr-right');
	$('body.MobileSmall #SecondaryNavWrap').insertAfter('#MainNavWrap2');
	$('body.Mobile #MainNavWrap2').appendTo('#sidr-right');
	$('body.Mobile #SecondaryNavWrap').insertAfter('#MainNavWrap2');
	$('body.Tablet #MainNavWrap2').appendTo('#sidr-right');
	$('body.Tablet #SecondaryNavWrap').insertAfter('#MainNavWrap2');
	$('body.Desktop #SecondaryNavWrap').insertBefore('#Payload');
	$('body.Desktop #MainNavWrap2').insertAfter('#HeaderWrap');
	$('body.Desktop #Logo').insertBefore('#HeaderRight');
	$('body.Tablet #Logo').insertBefore('#HeaderRight');
	$('body.Mobile #Logo').insertBefore('#HeaderRight');
	$('body.MobileSmall #Logo').insertBefore('#HeaderRight');

	$('body.MobileSmall #MyMidMichiganWrap').appendTo('#sidr-left');
	$('body.MobileSmall #QuickLinksWrap').insertAfter('#MyMidMichiganWrap');
	$('body.Mobile #MyMidMichiganWrap').appendTo('#sidr-left');
	$('body.Mobile #QuickLinksWrap').insertAfter('#MyMidMichiganWrap');
	$('body.Tablet #MyMidMichiganWrap').appendTo('#sidr-left');
	$('body.Tablet #QuickLinksWrap').insertAfter('#MyMidMichiganWrap');
	$('body.Desktop #MyMidMichiganWrap').appendTo('#HeaderBottom');
	$('body.Desktop #QuickLinksWrap').insertAfter('#MainNav');

	$('body.MobileSmall #ProxNavWrap').appendTo('#ViewMoreContent');
	$('body.Mobile #ProxNavWrap').appendTo('#ViewMoreContent');
	$('body.Tablet #ProxNavWrap').prependTo('#ColumnOne');
	$('body.Desktop #ProxNavWrap').prependTo('#ColumnOne');

	$('body.MobileSmall #ColumnOneWrap').appendTo('#ColumnTwoThree');
	$('body.Mobile #ColumnOneWrap').appendTo('#ColumnTwoThree');
	$('body.Tablet #ColumnOneWrap').prependTo('#Columns');
	$('body.Desktop #ColumnOneWrap').prependTo('#Columns');

	$('body.Desktop #ColumnThree').prependTo('#ColumnThreeWrap');
	$('body.Tablet #ColumnThree').insertAfter('#ColumnOne');
	$('body.Mobile #ColumnThree').insertAfter('#ColumnOne');
	$('body.MobileSmall #ColumnThree').insertAfter('#ColumnOne');

	$('body.Desktop #babyInfoContainer').insertAfter('div[id*="babyPhotoContainer"]');
	$('body.Tablet #babyInfoContainer').insertAfter('div[id*="babyPhotoContainer"]');
	$('body.Mobile #babyInfoContainer').insertAfter('div[id*="babyPhotoContainer"]');
	$('body.MobileSmall #babyInfoContainer').insertBefore('div[id*="babyPhotoContainer"]');

	$('body.Desktop .Designations .flexWrap h1').prependTo('.Designations .flexWrap');
	$('body.Tablet .Designations .flexWrap h1').prependTo('.Designations .flexWrap');
	$('body.Mobile .Designations .flexWrap h1').prependTo('.Designations .flexWrap');
	$('body.MobileSmall .Designations .flexWrap h1').appendTo('.Designations .flexWrap');

	$('body.Desktop #HomeLowerLeftLower').insertAfter('#HomeLowerLeftTop');
	$('body.Tablet #HomeLowerLeftLower').insertAfter('#HomeLowerRightWrap');
	$('body.Mobile #HomeLowerLeftLower').insertAfter('#HomeLowerRightWrap');
	$('body.MobileSmall #HomeLowerLeftLower').insertAfter('#HomeLowerRightWrap');

	$('body.Desktop #DrDetail .Designations .ScheduleAppointment').appendTo('.Designations .flexWrap .ScheduleAppointmentWrap');
	$('body.Tablet #DrDetail .ScheduleAppointmentWrap .ScheduleAppointment').appendTo('.Designations');
	$('body.Mobile #DrDetail .ScheduleAppointmentWrap .ScheduleAppointment').appendTo('.Designation');
	$('body.MobileSmall #DrDetail .ScheduleAppointmentWrap .ScheduleAppointment').appendTo('.Designations');

	$('body.Desktop #DrDetail .QuickInfo .flexWrap').prependTo('.Designations');
	$('body.Tablet #DrDetail .flexWrap').prependTo('.QuickInfo');
	$('body.Mobile #DrDetail .flexWrap').prependTo('.QuickInfo');
	$('body.MobileSmall #DrDetail .flexWrap').prependTo('.QuickInfo');

	$('body.Desktop .LocRight .LocRightBottom div#MapId').appendTo('.LocLeft .LocLeftBottom');
	$('body.Tablet .LocLeft .LocLeftBottom div#MapId').appendTo('.LocRight .LocRightBottom');
	$('body.Mobile .LocLeft .LocLeftBottom div#MapId').appendTo('.LocRight .LocRightBottom');
	$('body.MobileSmall .LocLeft .LocLeftBottom div#MapId').appendTo('.LocRight .LocRightBottom');

	//moving wait times on homepage
	$('body#Home.Desktop #WaitTime').prependTo('#HomeMiddle #WaitTimeWrap');
	$('body#Home.Tablet #WaitTime').prependTo('#HeaderWrap #Payload ul.Payloads li.MobileWait');
	$('body#Home.Mobile #WaitTime').prependTo('#HeaderWrap #Payload ul.Payloads li.MobileWait');
	$('body#Home.MobileSmall #WaitTime').prependTo('#HeaderWrap #Payload ul.Payloads li.MobileWait');

	//wrapping img with .focuspoint, but first checking to see if it exist, if it does do nothing if not wrap the data-focus variables
	if ($('.BannerImg .focuspoint').length > 0) {
		// do nothing
	} else {
		$('body.MobileSmall #Banner img, body.MobileSmall #Banner2 img').wrap('<div class="focuspoint" data-focus-x="-0.67" data-focus-y="0.00" data-focus-w="1600" data-focus-h="530" />');
	}

	if ($('.BannerImg .focuspoint').length > 0) {
		// do nothing
	} else {
		$('body.MobileSmall .FlexBanner img').wrap('<div class="focuspoint" data-focus-x="-0.67" data-focus-y="0.00" data-focus-w="1600" data-focus-h="530" />');
	}

	if (Math.max($(window).width(), window.innerWidth) < 1401) {
		$('.LocationsList > ul > li').each(function () {
			$(this).find('.SchedulingButtonWrap').appendTo($(this).find('.NamePhone'));
		});
	} else {
		$('.LocationsList > ul > li').each(function () {
			$(this).find('.SchedulingButtonWrap').appendTo($(this).find('.MinorDetails'));
		});
	}
}

function moveCTAPanel() {
	/* Call To Action */
	// If Move-CTA-Here exisits - move the CTA to that spot
	// If Move-CTA-Here does not exists - check to see if there is an h1 imediately followed by an h2 and move it after that h2
	// If no h2 exists imediately after an h2, move CTA after the h1 instead
	// If no Move-CTA-Here, H1 or H2 exists - move CTA to the top of the cmsPageContent
	var InsertHere;
	if ($('#LeftCol #CallToActionPanels, #NoCols #CallToActionPanels, #CareersLeftCol #CallToActionPanels').length > 0) {
		if ($('.Move-CTA-Here:first').length > 0) {
			$('#CallToActionPanels').appendTo('.Move-CTA-Here');
		}
		else if ($('.cmsPageContent h1:first').length > 0) {
			if ($('.cmsPageContent  h1:first').next('h2').length > 0) {
				InsertHere = $('.cmsPageContent h1:first').next('h2');
				$('#CallToActionPanels').insertAfter(InsertHere);
			}
			else {
				InsertHere = $('.cmsPageContent h1:first');
				$('#CallToActionPanels').insertAfter(InsertHere);
			}
		} else {
			$('#CallToActionPanels').prependTo('.cmsPageContent');
		}
	} else {
		if ($('#Interior.Tablet .Move-CTA-Here:first, .Mobile .Move-CTA-Here:first').length > 0) {
			$('#Interior.Tablet #ColumnThree #CallToActionPanels').appendTo('#Interior.Tablet .Move-CTA-Here');
			$('.Mobile #ColumnThree #CallToActionPanels').appendTo('.Mobile .Move-CTA-Here');
		}
		else if ($('#Interior.Tablet .cmsPageContent h1:first, .Mobile .cmsPageContent h1:first').length > 0) {
			if ($('#Interior.Tablet .cmsPageContent  h1:first, .Mobile .cmsPageContent h1:first').next('h2').length > 0) {
				InsertHere = $('.cmsPageContent h1:first').next('h2');
				$('#Interior.Tablet #ColumnThree #CallToActionPanels, .Mobile #ColumnThree #CallToActionPanels').insertAfter(InsertHere);
			}
			else {
				InsertHere = $('.cmsPageContent h1:first');
				$('#Interior.Tablet #ColumnThree #CallToActionPanels, .Mobile #ColumnThree #CallToActionPanels').insertAfter(InsertHere);
			}
		} else {
			$('#Interior.Tablet #ColumnThree #CallToActionPanels, .Mobile #ColumnThree #CallToActionPanels').prependTo('.cmsPageContent');
		}

		$('.Desktop .cmsPageContent #CallToActionPanels').prependTo('#ColumnThree');
		$('#RightCol.Tablet .cmsPageContent #CallToActionPanels').prependTo('#ColumnThree');
	}
	if ($('.Move-Inline-CTA-Here').length > 0) {
		$('.Tablet #Content .CTA, .Mobile #Content .CTA').insertAfter('.Move-Inline-CTA-Here');
		$('.Tablet #Content .CTAWide, .Mobile #Content .CTAWide').insertAfter('.Move-Inline-CTA-Here');
	} 

	$('.Desktop #Content .CTA, .Desktop #Content .CTAWide').insertBefore('.CTAOrig');
    
}
function showScheduleButton() {
	$('.LocationsList .locationWaitTimes').each(function () {
		$(this).find('a[href*="//mymidmichigan.midmichigan.net/MyChart/"], a[href*="//my.midmichigan.net/mychart"]').addClass('SchedulingButton').insertAfter(this).wrap('<div class="SchedulingButtonWrap"></div>');
		$(this).remove();
	});
}